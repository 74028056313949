const Form = ({
  maximum,
  defaultVal,
  onChange,
  onSubmit
}) => {
  const formattedMax = new Intl.NumberFormat('en-US').format(maximum);

  return (
    <form onSubmit={onSubmit}>
      <p>
        <label htmlFor="topick">Enter a number between 1 and {formattedMax}</label>
        <input
          id="topick"
          type="number"
          min="1"
          max={maximum}
          step="1"
          defaultValue={defaultVal}
          onChange={onChange}
        />
        <button type="submit">Pick &#8217;em!</button>
      </p>
    </form>
  )
}

export default Form;