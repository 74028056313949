import React, {
  useState,
  useEffect,
} from 'react';

import Form from './Form';
import Picks from './Picks';
import Loader from './Loader';
import DownloadCsv from './DownloadCsv';

import {
  JSON_URL,
  HEADERS,
} from './config';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [corps, setCorps] = useState([]);
  const [num, setNum] = useState(12);
  const [picks, setPicks] = useState([]);
  const [downloadIsHidden, setDownloadIsHidden] = useState(true);

  // Loads hooks JSON file from a URL. 
  useEffect(() => {
    if (corps.length) return;
    
    fetch(JSON_URL, { method: 'GET', mode: 'cors' })
      .then((response) => {
        let json = {};
        if (response.ok) {
          json = response.json();
        }
        return json;
      })
      .then((json) => {
        setCorps(json.entities);
        setLoading(!loading);
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e));
  });
  
  const onChange = (e) => {
    setNum(+e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    var i = 0;
    var chosen = [];

    while (i < num) {
      chosen.push(corps[random(corps.length)]);
      i++;
    }
    
    setPicks(chosen);
    setDownloadIsHidden(!chosen.length);
  }

  const random = (max) => {
    return Math.floor(Math.random() * max);
  }
  
  if (loading) {
    return <Loader color="#e15b64" />
  } else {

    return (
      <>
        <Form
          maximum={corps.length}
          defaultVal={num}
          onChange={onChange}
          onSubmit={onSubmit}
        />
        <DownloadCsv headers={HEADERS} data={picks} isHidden={downloadIsHidden} />
        <Picks headers={HEADERS} entities={picks} />
      </>
    )
  }

}


export default App;
