import { uniqueId } from 'lodash';
import { YAHOO_BASE_URL } from './config';
import { fixTicker } from './utils';

import TickerLink from './TickerLink';

const theaders = (h) => (<th key={uniqueId()}>{h}</th>);

const rows = (r) => {
  return (
    <tr key={uniqueId()}>
      <td>{r.name}</td>
      <td>
        <TickerLink
          url={`${YAHOO_BASE_URL}${fixTicker(r.ticker)}`}
          name={r.ticker}
          newtab={true}
        />
      </td>
      <td>{r.cusip}</td>
    </tr>
  )
}

const Picks = ({headers, entities}) => {
  if(!entities.length) return null;

  return (
    <>
      <table>
        <thead>
          <tr>
            {headers.map(theaders)}
          </tr>
        </thead>
        <tbody>
          {entities.map(rows)}
        </tbody>
      </table>
      <p id="toplink"><a href="#top">back to top</a></p>
    </>
  )
}

export default Picks;